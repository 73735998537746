@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");

* {
  box-sizing: border-box;
}

html,
body,
#root,
#root > div {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  
}

body {
  font-family: sans-serif;
  background: rgb(17, 25, 33);
  background: linear-gradient(
    180deg,
    rgba(17, 25, 33, 1) 0%,
    rgba(58, 64, 71, 1) 100%
  );
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}


